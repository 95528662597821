<template>
    <div class="checkbox-group">
        <slot></slot>
    </div>
</template>

<script>
import createComponent from '../../utils/createComponent';
export default createComponent({
    name: 'CheckboxGroup',
    props: {
        value: [Boolean, Number, String, Array],
        disabled: Boolean,
        multiple: Boolean
    },
    provide(){
        return {
            checkboxGroup: this
        }
    },
    data(){
        return {
            checkValue: undefined
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(newValue){
                this.checkValue = newValue;
            }
        }
    }

})
</script>

<style>
.checkbox-group{
    display: inline-block;
}
</style>