<template>
    <div class="select" :style="{'width': width}">
        <el-popover
            ref="popover"
            v-model="visible"
            :visible-arrow="false"
            :popper-class="popperClassName"
            :disabled="disabled"
            v-bind="{
                trigger: 'click',
                placement: 'bottom'
            }">
            <template slot="reference">
                <div class="select_wrapper" :class="{
                    'select-header': visible,
                    'is-disabled': disabled
                }">
                    <input type="text" readonly="readonly" autocomplete="off" :placeholder="placeholder || '请选择'" class="input-value" :value="inputValue">
                </div>
            </template>
            <template>
                <div class="select_wrapper select-header" ref="selectHeader" :class="{'is-disabled': disabled}" @click="visible = false">
                    <input type="text" readonly="readonly" autocomplete="off" :placeholder="placeholder || '请选择'" class="input-value" :value="inputValue">
                </div>
                <div class="option-menu_container">
                    <div class="option-menu" ref="menu">
                        <div class="select-option"
                            :class="{
                                'is-disabled': item.disabled,
                                'is-active': innerValue !== undefined && innerValue === item[valueKey]
                            }"
                            v-for="(item, index) in options" :key="index" 
                            @click="optionClick(item, index)"
                        >
                            <slot name="option" v-bind="{...item, '$index': index}"><span>{{item[labelKey]}}</span></slot>
                        </div>
                    </div>
                </div>
            </template>
        </el-popover>
    </div>
</template>
<script>
import createComponent from '../../utils/createComponent';
export default createComponent({
    name: 'Select',
    props: {
        value: String | Number,
        placeholder: String,
        disabled: Boolean,
        options: Array,
        width: String,
        valueKey:{
            type: String,
            default: 'value'
        },
        labelKey:{
            type: String,
            default: 'label'
        },
        popperClass: String,
    },
    data(){
        return {
            visible: false,
            innerValue: this.value

        }
    },
    mounted() {
        this.setStyle();
    },
    watch: {
        visible(newVal){
            if(newVal){
                this.setStyle();
            }
        },
        value(newVal){
            this.innerValue = newVal;
        }
    },
    computed: {
        popperClassName(){
            return `select-popper ${this.popperClass || ''}`;
        },
        inputValue(){
            if(!this.options){
                return this.innerValue;
            }else{
                return (this.options.find(item=>this.innerValue === item[this.valueKey]) || {})[this.labelKey] || this.innerValue || '';
            }
        }
    },
    methods: {
        optionClick(item){
            let value = item[this.valueKey];
            this.visible = false;
            if(value !== this.innerValue){
                this.innerValue = value;
                this.$emit('input', value);
                this.$emit('change', value);
            }
        },
        setStyle(){
            if(!this.$refs.popover) return;
            let referenceElm = this.$refs.popover.referenceElm;
            let popperElm = this.$refs.popover.$refs.popper;
            popperElm.style.width = referenceElm.offsetWidth + 'px';
            popperElm.style.setProperty("--referenceHeight", referenceElm.offsetHeight + 'px');
            if(this.options){
                let index = this.options.findIndex(item=>item[this.valueKey] === this.innerValue);
                if(index >= 0){
                    setTimeout(()=>{
                        this.$refs.menu.scrollTop = index * 30;
                    })
                }
            }
        }
    }
})
</script>
<style lang="scss" scoped>
    .select{
        display: inline-block;
        width: 100%;
        height: 34px;
        position: relative;
        vertical-align: middle;
    }
    .select_wrapper{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &.select-header .input-value{
            background-color: #FFFFFF;
            background-image: url("~@icon/select_up.png");
        }
        &.is-disabled{
            // filter: opacity(0.6);
            filter: contrast(0.8);
            .input-value{
                cursor: not-allowed;
            }
        }
        .input-value{
            width: 100%;
            height: 100%;
            padding: 8px;
            padding-right: 20px;
            box-sizing: border-box;
            background: #F1F2F6 url("~@icon/select_down.png") no-repeat center right 4px / 14px 14px;
            border-radius: 5px;
            cursor: pointer;
            @include text-style-default;
            line-height: 18px;
            @include ellipsis;
            &::-webkit-input-placeholder {
                color: #C1C9DA;
                text-overflow: clip;
            }
        }
    }
    .option-menu_container{
        min-width: 100%;
        padding: 6px 0;
        // background: #fff;
        // box-shadow: 0px 2px 8px -2px rgba(136, 136, 136, 0.5);
        // border-radius: 5px;
        .option-menu{
            min-width: 100%;
            max-height: 165px;
            overflow-x: hidden;
            overflow-y: auto;
            .select-option{
                width: 100%;
                height: 30px;
                padding: 0 8px;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                flex-wrap: nowrap;
                cursor: pointer;
                white-space: nowrap;
                &:hover{
                    background: #F2F5F6;
                }
                &.is-active{
                    color: #3078FF;
                }
                &.is-disabled{
                    color: #9FA3AE;
                    pointer-events: none;
                }
            }
        }
    }
</style>

<style lang="scss">
    .select-popper{
        min-width: initial;
        padding: 0;
        background: #FFFFFF;
        box-shadow: 0px 2px 8px -2px rgba(136,136,136,0.5);
        border-radius: 5px;
        @include text-style-default;
        .select-header{
            height: var(--referenceHeight);
            position: absolute;
            left: 0;
        }
        &[x-placement^=bottom]{
            padding-top: var(--referenceHeight);
            margin-top: calc(0px - var(--referenceHeight));
            .select-header{
                top: 0;
                .input-value{
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
        }
        &[x-placement^=top]{
            padding-bottom: var(--referenceHeight);
            margin-bottom: calc(0px - var(--referenceHeight));
            .select-header{
                bottom: 0;
                .input-value{
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                }
            }
        }
    }
</style>