<template>
    <div class="checkbox" :class="{
        'is-checked': checked,
        'is-disabled': checkboxDisabled
    }" @click="handleClick">
        <div class="checkbox-icon"></div>
        <div class="checkbox-text" v-if="$slots.default || label">
            <slot>{{label}}</slot>
        </div>
    </div>
</template>
<script>
import createComponent from '../../utils/createComponent';
export default createComponent({
    name: 'Checkbox',
    props: {
        value: [Boolean, Number, String],
        label: String,
        disabled: Boolean,
        trueValue: String | Number,
        falseValue: String | Number
    },
    inject: {
        checkboxGroup: {
            default: undefined
        }
    },
    data(){
        return {
            checkValue: this.value
        }
    },
    watch: {
        value(newValue){
            this.checkValue = newValue;
        }
    },
    computed: {
        checkboxDisabled(){
            return (this.checkboxGroup || {}).disabled || this.disabled;
        },
        checkTrueValue(){
            let trueValue = this.trueValue;
            if(trueValue == undefined){
                return true;
            }else{
                return trueValue;
            }
        },
        checkFalseValue(){
            let falseValue = this.falseValue;
            if(falseValue == undefined && typeof this.checkTrueValue == 'boolean'){
                return false;
            }else{
                return falseValue;
            }
        },
        checked(){
            if(this.checkboxGroup){
                let checkValue = this.checkboxGroup.checkValue;
                if(this.checkboxGroup.multiple && Array.isArray(checkValue)){
                    return checkValue.includes(this.checkTrueValue);
                }else{
                    return checkValue === this.checkTrueValue;
                }
            }
            return this.checkValue === this.checkTrueValue;
        }
    },
    methods: {
        handleClick(){
            if(this.checkboxDisabled) return;
            if(this.checkboxGroup){
                let checkValue = this.checkboxGroup.checkValue;
                if(this.checkboxGroup.multiple && Array.isArray(checkValue)){
                    if(this.checked){
                        let index = checkValue.findIndex(item=>item == this.checkTrueValue);
                        checkValue.splice(index, 1);
                    }else{
                        checkValue.push(this.checkTrueValue);
                    }
                }else{
                    if(this.checked){
                        checkValue = this.checkFalseValue;
                    }else{
                        checkValue = this.checkTrueValue;
                    }
                }
                this.checkboxGroup.$emit('input', checkValue);
                this.checkboxGroup.$emit('change', checkValue);
            }else{
                if(this.checked){
                    this.checkValue = this.checkFalseValue;
                }else{
                    this.checkValue = this.checkTrueValue;
                }
                this.$emit('input', this.checkValue);
                this.$emit('change', this.checkValue);
            }
        }
    }
})
</script>
<style lang="scss" scoped>
.checkbox{
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
    cursor: pointer;
    vertical-align: middle;
    margin-right: 12px;
    &:last-of-type{
        margin-right: 0;
    }
    &.is-checked{
        .checkbox-icon{
            background-image: url("~@icon/checkbox_select.png");
        }
    }
    &.is-disabled{
        cursor: not-allowed;
        &.is-checked{
            .checkbox-icon{
                background-image: url("~@icon/checkbox_select_disabled@2x.png");
            }
        }
        .checkbox-icon{
            background-image: url("~@icon/checkbox_disabled.png");
        }
        .checkbox-text{
            color: #AEB7CB;
        }
    }
    .checkbox-icon{
        display: inline-block;
        width: 20px;
        height: 20px;
        background: url("~@icon/checkbox_normal.png") no-repeat center center / 100% 100%;
    }
    .checkbox-text{
        display: inline;
        margin-left: 5px;
        @include text-style-default;
        color: #798087;
        line-height: 20px;
    }
}
</style>