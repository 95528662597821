<template>
    <div class="radio" :class="{
        'is-checked': checked,
        'is-disabled': radioDisabled
    }" @click="handleClick">
        <div class="radio-icon"></div>
        <div class="radio-text" v-if="$slots.default || label">
            <slot>{{label}}</slot>
        </div>
    </div>
</template>
<script>
import createComponent from '../../utils/createComponent';
export default createComponent({
    name: 'Radio',
    props: {
        value: Boolean | Number | String,
        label: String,
        disabled: Boolean,
        trueValue: String | Number
    },
    inject: {
        radioGroup: {
            default: undefined
        }
    },
    data(){
        return {
            checkValue: this.value
        }
    },
    watch: {
        value(newVal){
            this.checkValue = newVal;
        }
    },
    computed: {
        radioDisabled(){
            return (this.radioGroup || {}).disabled || this.disabled;
        },
        checkTrueValue(){
            let trueValue = this.trueValue;
            if(trueValue == undefined){
                return true;
            }else{
                return trueValue;
            }
        },
        checked(){
            if(this.radioGroup){
                return this.radioGroup.checkValue === this.checkTrueValue;
            }
            return this.checkValue === this.checkTrueValue;
        }  
    },
    methods: {
        handleClick(){
            if(this.radioDisabled) return;
            if(!this.checked){
                if(this.radioGroup){
                    let checkValue = this.checkTrueValue;
                    this.radioGroup.$emit('input', checkValue);
                    this.radioGroup.$emit('change', checkValue);
                }else{
                    this.checkValue = this.checkTrueValue;
                    this.$emit('input', this.checkValue);
                    this.$emit('change', this.checkValue);
                }
            }
        }
    }
})
</script>
<style lang="scss" scoped>
.radio{
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
    cursor: pointer;
    vertical-align: middle;
    margin-right: 12px;
    &:last-of-type{
        margin-right: 0;
    }
    &.is-checked{
        .radio-icon{
            background-image: url("~@icon/radio_select.png");
        }
    }
    &.is-disabled{
        cursor: not-allowed;
        &.is-checked{
            .radio-icon{
                background-image: url("~@icon/radio_select_disabled@2x.png");
            }
        }
        .radio-icon{
            background-image: url("~@icon/radio_disabled@2x.png");
        }
        .radio-text{
            color: #AEB7CB;
        }
    }
    .radio-icon{
        display: inline-block;
        width: 20px;
        height: 20px;
        background: url("~@icon/radio_normal.png") no-repeat center center / 100% 100%;
    }
    .radio-text{
        display: inline;
        margin-left: 5px;
        @include text-style-default;
        color: #798087;
    }
}
</style>