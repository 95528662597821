const layoutMain = () => import('@/layout/Main.vue');
const routes = [
    {
        path: '/',
        redirect: 'main'
    }, {
        path: '/main',
        component: layoutMain,
        children: [
            {
                path: '/',
                redirect: 'teamManagement'
            }, {
                path: 'teamManagement',
                name: 'teamManagement',
                component: () => import("@/views/teamManagement/Main.vue")
            }, {
                path: 'matchManagement/matchDetail',
                name: 'matchDetail',
                component: () => import("@/views/matchManagement/MatchDetail.vue")
            }, {
                path: 'matchManagement/matchRule',
                name: 'matchRule',
                component: () => import("@/views/matchManagement/MatchRule.vue")
            }, {
                path: 'gameArrangement',
                component: () => import("@/views/gameArrangement/Main.vue"),
                children: [
                    {
                        path: '/',
                        redirect: 'against'
                    }, {
                        path: 'against',
                        name: 'against',
                        component: () => import("@/views/gameArrangement/Against.vue")
                    }, {
                        path: 'projectionScreen',
                        name: 'projectionScreen',
                        component: () => import("@/views/gameArrangement/ProjectionScreen.vue")
                    }, {
                        path: 'recordScores',
                        name: 'recordScores',
                        component: () => import("@/views/gameArrangement/RecordScores.vue")
                    }, {
                        path: 'integral',
                        name: 'integral',
                        component: () => import("@/views/gameArrangement/Integral.vue")
                    }
                ]
            }, {
                path: 'matchData',
                component: () => import("@/views/matchData/Main.vue"),
                children: [
                    {
                        path: '/',
                        redirect: 'againstQuery'
                    }, {
                        path: 'againstQuery',
                        name: 'againstQuery',
                        component: () => import("@/views/matchData/AgainstQuery.vue")
                    }, {
                        path: 'result',
                        name: 'result',
                        component: () => import("@/views/matchData/Result.vue")
                    }, {
                        path: 'integral',
                        name: 'integral',
                        component: () => import("@/views/matchData/Integral.vue")
                    }, {
                        path: 'MPQuery',
                        name: 'MPQuery',
                        component: () => import("@/views/matchData/MPQuery.vue")
                    }, {
                        path: 'delegationRanking',
                        name: 'delegationRanking',
                        component: () => import("@/views/matchData/DelegationRanking.vue")
                    }
                ]
            }
        ]
    }, {
        path: '/login',
        name: 'login',
        meta: {
            login: false
        },
        component: () => import("@/views/common/Login.vue")
    }, {
        path: '/helpCenter',
        name: 'helpCenter',
        component: () => import("@/views/helpCenter/Main.vue")
    }, {
        path: "*",
        redirect: "/404"
    }, {
        path: "/404",
        meta: {
            login: false
        },
        component: () => import("@/views/common/404.vue")
    }
]
export default routes;